import React from "react";

export const Awards = (props) => {
  // Assuming you have an array of award images
  const awardImages = [
    "img/msme.jpg",
    "img/award.png",
    "img/ach.jpg",
    "img/shield.png",
  ];

  return (
    <div id="awards">
      <div style={{ paddingBottom: "100px" }} className="container">
        <div
          style={{ paddingTop: "30px", textAlign: "center" }}
          className="col-md-10 col-md-offset-1 section-title"
        >
          <h2 className="awards">Awards & Recognition</h2>
        </div>
        <div className="row">
          {awardImages.map((image, index) => (
            <div
              style={{
                padding: "20px",
                paddingLeft: "60px",
              }}
              key={index}
              className="col-xs-12 col-md-6 pad_fix"
            >
              <img
                style={{ height: "400px" }}
                src={image}
                className="img-responsive"
                alt={`Award ${index + 1}`}
              />
            </div>
          ))}
          <div
            style={{
              padding: "20px",
              paddingLeft: "60px",
            }}
            className="col-xs-12 col-md-12 pad_fix"
          >
            <img
              style={{ width: "1000px" }}
              src="img/cert.jpg"
              className="img-responsive"
              alt="Award 2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
