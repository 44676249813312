import React from "react";

export const About2 = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 pad_fix">
            {" "}
            <img src="img/Vision.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Mission</h2>
              <p>
                Huesoul Management Private Limited was designed to bridge the
                gap between specialty sectors that were manpower-starved and
                hungry for opportunities. We are renowned for being the finest
                combination of commitment, quality service, business philosophy,
                professional ethics, dedication, and integrity. We offer
                exclusive services in recruitment process outsourcing and
                professional staffing through our highly qualified and dedicated
                team of recruitment professionals. We provide a complete range
                of manpower support services, including permanent placements,
                contractual workforce, as well as short-term and project-based
                recruitment. We used to provide manpower and staff in the
                IT-Telecom sectors, corporate sectors, government sectors,
                banking, manufacturing sectors, schools, institutions, gyms,
                hotels, resorts, etc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
