import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Management</h2>
          <p>
            Unlocking Vision, Nurturing Success: Meet the Minds Behind Huesoul
            Management
          </p>
        </div>
        <div id="row">
          <div>
            {props.data
              ? props.data.map((d, i) => (
                  <center>
                    <div
                      style={{ width: "50%" }}
                      key={`${d.name}-${i}`}
                      className="col-md-3 col-sm-6 team"
                    >
                      <div className="thumbnail">
                        {" "}
                        <img src={d.img} alt="..." className="team-img" />
                        <div className="caption">
                          <h4>{d.name}</h4>
                          <p>{d.job}</p>
                        </div>
                      </div>
                    </div>
                  </center>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};
