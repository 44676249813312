import React from "react";

export const About3 = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 mobile_view pad_fix">
            {" "}
            <img
              src="img/Mission.jpg"
              className="img-responsive margin_zero"
              alt=""
            />{" "}
          </div>

          <div className="col-xs-12 col-md-6 ">
            <div className="about-text">
              <h2 className="Vision">Vision</h2>
              <p>
                We strive to build a bond with our clients in a positive and
                lasting manner. To achieve this, we create an unlimited
                partnership through mutual goal setting, communication, and
                accountability. Our customers are assured of honest and
                dependable service, including punctual delivery. We look forward
                to building a long-term relationship with our esteemed customers
                for the mutual benefit of both parties.
              </p>
            </div>
          </div>

          <div
            style={{ paddingLeft: "20px" }}
            className="col-xs-12 col-md-6 desktop_view"
          >
            {" "}
            <img src="img/Mission.jpg" className="img-responsive" alt="" />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
