import React from "react";

export const Achievement = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 pad_fix">
            {" "}
            <img src="img/ach.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Achievement</h2>
              <p>
                THE HUESOUL MANAGEMENT PRIVATE LIMITED was initiated by{" "}
                <strong>Mr. M.S. Karthik Reddy, Managing Director</strong>, who
                has vast experience in the field of facility management,
                security services, manpower outsourcing, and consultancy
                services. The company provides a complete range of services to
                small, medium, and large businesses, corporate entities, MNC
                companies, star hotels, hospitals, and state and central
                government departments and institutions.{" "}
                <strong>
                  Mr. Karthik Reddy has been honored by the President of India
                  with an award for MSME National Award for the year 2010
                </strong>
                , recognizing his outstanding entrepreneurship and performance
                by the Ministry of Micro, Small, and Medium Enterprises,
                Government of India.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
