import React from "react";

export const About1 = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          {" "}
          <div className="col-xs-12 col-md-6 mobile_view pad_fix">
            {" "}
            <img src="img/about1.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Objective</h2>
              <p>
                Huesoul Management Private Limited has set new standards in all
                the above-mentioned services. A correct blend of skills and
                resources, developed and perfected by our team, has enabled us
                to assemble an expert team of professionals. This team is
                reputed for providing intensive services to a diverse range of
                clients, including well-known MNCs, corporate houses, hotels,
                hospitals, and industries & institutions – from the smallest
                residences to the largest corporations. We have been pro.
              </p>
            </div>
          </div>
          <div
            style={{ paddingLeft: "20px" }}
            className="col-xs-12 col-md-6 desktop_view"
          >
            {" "}
            <img src="img/about1.jpg" className="img-responsive" alt="" />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
