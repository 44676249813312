import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const openWhatsAppChat = (e) => {
    e.preventDefault();
    const messageContent = `Name: ${name}\nEmail: ${email}\nMessage: ${message}`;
    const whatsappURL = `https://wa.me/919980699555?text=${encodeURIComponent(
      messageContent
    )}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form onSubmit={openWhatsAppChat}>
                <div className="row">
                  <div style={{ paddingLeft: "16px" }} className="col-md-6">
                    <div className="form-group">
                      <input
                        style={{ borderRadius: "5px", height: "40px" }}
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div style={{ paddingLeft: "16px" }} className="col-md-6">
                    <div className="form-group">
                      <input
                        style={{ borderRadius: "5px", height: "40px" }}
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    style={{ borderRadius: "5px" }}
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Please write your requirement or query here..."
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p style={{ fontSize: "18px" }}>
                <strong>Huesoul Management Private Ltd</strong> <br /> An Iso
                Certified Company
                <br />
                &#169; All Rights Reserved
              </p>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                <strong>
                  {props.data ? props.data.phone : "loading"} <br />
                  {props.data ? props.data.phone2 : "loading"}
                </strong>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa  fa-fax"></i> Fax
                </span>{" "}
                <strong>080 - 23312527</strong>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"} <br />
                {props.data ? props.data.email2 : "loading"} <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            Designed with ❤️ by <a href="https://www.quantech.pro/">QuanTech</a>
          </p>
        </div>
      </div>
    </div>
  );
};
