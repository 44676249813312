import React from "react";

function choose() {
  return (
    <div className="mob_view">
      <center>
        <h2
          style={{
            fontSize: "28px",
            paddingTop: "20px",
            textDecoration: "underline",
          }}
        >
          We Are Certified!
        </h2>
        <img
          style={{ width: "60px", paddingBottom: "20px" }}
          src="/img/iso.png"
          alt="iso certification"
        />
        <img
          style={{ width: "60px", paddingBottom: "20px", marginLeft: "25px" }}
          src="/img/Incorporation.png"
          alt="incorporation certificate"
        />
        <img
          style={{ width: "60px", paddingBottom: "20px", marginLeft: "25px" }}
          src="/img/nsic.png"
          alt="nsic certificate"
        />
        <img
          style={{ width: "60px", paddingBottom: "20px", marginLeft: "25px" }}
          src="/img/msme.png"
          alt=" msme certificate"
        />
      </center>
    </div>
  );
}

export default choose;
