import React, { useState } from "react";
import data from "../data/clients.json";

function Client() {
  const maxItemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);

  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / maxItemsPerPage);

  const paginateData = (pageNumber) => {
    const startIndex = (pageNumber - 1) * maxItemsPerPage;
    const endIndex = startIndex + maxItemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const slicedData = paginateData(currentPage);

  return (
    <div className="text-center">
      <div className="container">
        <div
          style={{ paddingBottom: "40px", paddingTop: "40px" }}
          className="col-md-8 col-md-offset-2 section-title section-title1"
        >
          <h2>Departments We Work With</h2>
        </div>
      </div>
      <div
        className="client-fix"
        style={{
          textAlign: "left",
          margin: "0 auto",
          maxWidth: "1200px", // Set your desired maximum width
          padding: "0 20px", // Add some padding for better spacing
        }}
      >
        <ul
          className="cols_fix"
          style={{ listStyleType: "disc", columns: "2", columnGap: "100px" }}
        >
          {slicedData.map((item, index) => (
            <li key={index}>{item["DEPARTMENT NAME englih "]}</li>
          ))}
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderRadius: "50%",
          overflow: "hidden",
          paddingBottom: "40px",
        }}
      >
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            style={{
              margin: "0 5px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "none",
              outline: "none",
              backgroundColor:
                currentPage === index + 1 ? "#6374FF" : "transparent",
              color: currentPage === index + 1 ? "white" : "#6374FF",
              fontWeight: currentPage === index + 1 ? "bold" : "normal",
              cursor: "pointer",
            }}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <a
        style={{ marginBottom: "40px" }}
        download={true}
        href="/Client-list.xlsx"
        className="btn btn-custom btn-lg page-scroll"
      >
        Download Complete List
      </a>{" "}
    </div>
  );
}

export default Client;
